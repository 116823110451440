import axios from "axios";
import { format } from "date-fns";
import { Edit, Trash } from "iconsax-react";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import DeleteModal from "../../../commons/components/deleteModal.jsx";
import MultipleString from "../../../commons/components/multipleString.jsx";
import Spinner from "../../../commons/components/spinner";
import Table from "../../../commons/components/table";
import { commonConfigs, urls } from "../../../config/config.js";
import UpdateAccessRoleModal from "../components/updateAccessRolesModal";

const AccessRolesLayout = () => {
  const [load, setLoad] = useState(false);
  const [isUpdateModal, setUpdateModal] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [rowToEdit, setRowToEdit] = useState();
  const [roleData, setRoleData] = useState([]);
  let getAccessRolesUrl = ``;
  let deleteAccessRolesUrl = ``;
  let apiHeaders;

  if (process.env.REACT_APP_ENV === "staging") {
    getAccessRolesUrl =
      urls.baseUrl +
      commonConfigs.apiUrls.getAllAccessRoles();
    deleteAccessRolesUrl = urls.baseUrl;
    apiHeaders = commonConfigs.apiHeader;
  } else {
    getAccessRolesUrl =
      urls.prodUrl +
      commonConfigs.apiUrls.getAllAccessRoles();
    deleteAccessRolesUrl = urls.prodUrl;
    apiHeaders = commonConfigs.prodApiHeader;
  }

  async function getAccessRoles() {
    await axios
      .get(getAccessRolesUrl, { headers: apiHeaders })
      .then((res) => {
        console.log(res.data.result.data);
        setRoleData(res.data.result.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getAccessRoles();
  }, []);

  const columns = useMemo(() => [
    {
      Header: "Role Id",
      accessor: "role_id",
    },
    {
      Header: "Role Name",
      accessor: "role_name",
    },
    {
      Header: "Access Type",
      accessor: "access_rights",
      Cell: ({ row }) => {
        let accessType = [];
        if (row.original.access_rights.length > 0) {
          accessType = row.original.access_rights.map((obj) =>
            obj.access_name_option
              ? obj.access_name + " [" + obj.access_name_option + "]"
              : obj.access_name
          );
        }
        return (
          <div className="text-sm text-gray-900">
            <MultipleString data={accessType} />
          </div>
        );
      },
    },
    {
      Header: "Last Updated By",
      accessor: "updated_by",
      Cell: ({ value }) => (value ? value : "-"),
    },
    {
      Header: "Last Updated On",
      accessor: "updated_on",
      Cell: ({ row }) => {
        if (row.values.updated_on) {
          row.values.updated_on = format(
            new Date(row.values.updated_on),
            "dd MMM yyyy HH:mm"
          );
          var date = row.values.updated_on;

          if (date) {
            return <div className="text-sm text-gray-900">{date}</div>;
          }
        }
        return <div className="text-sm text-gray-900">-</div>;
      },
    },
    {
      id: "edit",
      Cell: ({ row }) => {
        return (
          <div
            className=" text-gray-900 flex justify-end space-x-3"
            style={{ paddingRight: "15px" }}
          >
            <button
              type="button"
              onClick={() => {
                setUpdateModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Edit aria-hidden="true" className="w-6 h-6" />
            </button>
            <button
              type="button"
              onClick={() => {
                setDeleteModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Trash aria-hidden="true" className="w-6 h-6" />
            </button>
          </div>
        );
      },
    },
  ]);

  if (!load) {
    return (
      <div className="w-full p-6">
        <div className="flex justify-end items-center mb-12 mt-2">
          {/* Button for 'Create Access Role' */}
          <Link to="/accessroles/create">
            <button
              type="button"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-12 rounded-full"
            >
              Create Access Role
            </button>
          </Link>
        </div>
        {roleData != null ? (
          <Table
            columns={columns}
            data={roleData}
            title="All Roles"
            filters={columns.map((column) => column.accessor)}
          />
        ) : (
          <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
            <div className="flex place-content-center w-full">
              <Spinner className="w-20 h-20" />
            </div>
          </div>
        )}

        {isUpdateModal && (
          <UpdateAccessRoleModal
            setOpenModal={setUpdateModal}
            load={setLoad}
            row={rowToEdit}
          />
        )}
        {isDeleteModal && (
          <DeleteModal
            setDeleteModal={setDeleteModal}
            load={setLoad}
            url={deleteAccessRolesUrl + commonConfigs.apiUrls.deleteAccessPage(rowToEdit.role_id)}
          />
        )}
      </div>
    );
  }
};

export default AccessRolesLayout;
