import axios from "axios";
import { Edit, Trash } from "iconsax-react";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import DeleteModal from "../../../../commons/components/deleteModal";
import Spinner from "../../../../commons/components/spinner";
import Table from "../../../../commons/components/table";
import { commonConfigs } from "../../../../config/config";
import UpdateSubscriptionModal from "../components/updateSubscriptionModal";

const SubscriptionLayout = () => {
  const [load, setLoad] = useState(true);
  const [isUpdateModal, setUpdateModal] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState(null);
  const [rowToEdit, setRowToEdit] = useState();

  const getAllSubscriptionPlanURL = commonConfigs.apiUrls.GetAllSubscriptionPlan();

  async function getSubscriptionPlans() {
    await axios
      .get(getAllSubscriptionPlanURL, { headers: commonConfigs.apiHeader, })
      .then((res) => {
        console.log(res.data.result.data);
        setData(res.data.result.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (load) {
      getSubscriptionPlans().catch((err) => {
        console.log("get subscriptionPlans err: " + err);
      });
    }
  }, [load]);

  const columns = useMemo(() => [
    {
      Header: "Subscription Plan",
      accessor: "subscription_plan",
    },
    {
      Header: "Subscription Credits",
      accessor: "subscription_credits",
    },
    {
      Header: "Validity",
      accessor: "subscription_validity",
    },
    {
      Header: "Description",
      accessor: "subscription_desc",
    },
    {
      id: "edit",
      Cell: ({ row }) => {
        return (
          <div className=" text-gray-900  flex space-x-3 ">
            <button
              type="button"
              onClick={() => {
                setUpdateModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Edit aria-hidden="true" className="w-6 h-6" />
            </button>
            <button
              type="button"
              onClick={() => {
                setDeleteModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Trash aria-hidden="true" className="w-6 h-6" />
            </button>
          </div>
        );
      },
    },
  ]);

  var mock = [
    {
      plan_name: "Regular",
      plan_credits: "30",
      plan_validity: "3 Months",
      plan_description: "Test 1"
    },
    {
      plan_name: "Preminum",
      plan_credits: "45",
      plan_validity: "6 Months",
      plan_description: "Test 2"
    },
    {
      plan_name: "Pay Per Use",
      plan_credits: "-",
      plan_validity: "-",
      plan_description: "Test 3"
    },
  ];

  if (!load) {
    return (
      <div className="w-full p-6">
        <div className="md:flex md:items-center md:justify-between">
          {/* <div className="flex-1 min-w-0">
            <Breadcrumbs pages={pages} />
          </div> */}
          <h2 style={{ marginLeft: '8px', fontSize: '18px', marginBottom: '25px', fontWeight: 'bold', color: '#2A3042' }}>All Subscription Plans</h2>
          {/* button for 'Create Project' */}
          <Link to="/resource-groups/configurations/subscriptions/create">
            <button type="button"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-12 rounded-full mr-10"
              style={{ float: 'right' }}>
              Create New Plan
            </button>
          </Link>
        </div>
        {data != null ? (
          <Table
            columns={columns}
            data={data}
            filters={columns.map((column) => column.accessor)}
          />

        ) : (
          <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
            <div className="flex place-content-center w-full">
              <Spinner className="w-20 h-20" />
            </div>
          </div>
        )}

        {isUpdateModal && (
          <UpdateSubscriptionModal
            setUpdateModal={setUpdateModal}
            load={setLoad}
            row={rowToEdit}
          />
        )}
        {isDeleteModal && (
          <DeleteModal
            setDeleteModal={setDeleteModal}
            load={setLoad}
            url={commonConfigs.apiUrls.DeleteSubscriptionPlan(rowToEdit.subscription_id)}
          />
        )}
      </div>
    );
  }
};

export default SubscriptionLayout;