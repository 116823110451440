import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { Component, Fragment } from "react";
import navigation from "../../../commons/navbar/constants/navRoutes.jsx";
import { commonConfigs } from "../../../config/config.js";
import firebaseConfig from "../../../config/firebaseConfig.js";
import { setUser } from "../../../services/tokenService.js";
let userLogin;
// const userLogin = commonConfigs.apiUrls.login();
const ssoLogin = commonConfigs.apiUrls.ssoAdminAuth();

// console.log(userLogin)

export default class OtpModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: "",
      disabled: true,
      isloading: false,
      openModal: true,
      ssoLogin: "",
      ssoHeader: "",
      userLogin: "",
      userHeader: "",
      orgId: ""
    };
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.isOpen = this.isOpen.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOTPChange = this.handleOTPChange.bind(this);
  }
  isOpen() {
    return this.state.openModal;
  }
  open() {
    console.log("Opening", this.isOpen());
    this.setState({ openModal: true });
    return this.isOpen();
  }

  close() {
    this.setState({ openModal: false });
    this.props.setOpenModal(false);
  }

  async handleSubmit(event) {
    this.setState({
      isloading: true,
    });
    event.preventDefault();
    if (this.state.otp !== "") {
      window.confirmationResult
        .confirm(this.state.otp)
        .then(function (result) {
          return result;
        })
        .then((result) => {
          if (result) {
            const data = {
              email: this.props.email,
              mobile: this.props.mobile,
            };
            console.log(this.state.ssoLogin, this.state.userLogin);
            axios
              .post(this.state.ssoLogin, data, {
                headers: this.state.ssoHeader,
              })
              .then((result) => {
                try {
                  if (result.data.status != "success") {
                    alert("danger");
                  }
                  var body = {
                    account_mobile: this.props.mobile,
                    account_email: this.props.email ?? null,
                    account_id: result.data.result.data.account_id,
                    sso_id: result.data.result.data.sso_id,
                    org_id:
                      result.data.result.data.org_id ??
                      this.state.orgId,
                  };
                  localStorage.setItem("ssoId", result.data.result.data.sso_id);
                  console.log(body);
                  axios
                    .post(this.state.userLogin, body, { 
                      headers: this.state.userHeader 
                    })
                    .then((res) => {
                      console.log(res);
                      if (res.data.status == "success") {
                        const user = {
                          account_id: res.data.result.data.account_id,
                          sso_id: result.data.result.data.sso_id,
                          org_id: result.data.result.data.org_id,
                          email: this.props.email,
                          mobile: this.props.mobile,
                          role_id: res.data.result.data.role_id,
                          access_token: res.data.result.data.access_token,
                          company_id: res.data.result.data.company_id,
                          access: res.data.result.data.access,
                        };
                        console.log(user);
                        localStorage.setItem(
                          "roleid",
                          res.data.result.data.role_id
                        );
                        let accessArr = res.data.result.data.access;
                        let landingPath = "";
                        if (accessArr.length > 0) {
                          navigation.forEach((navItem) => {
                            accessArr.forEach((access, i) => {
                              if (access.access_name == "Sandbox") {
                                landingPath = `/sandbox`;
                                return landingPath;
                              }
                              if (
                                navItem.subNav &&
                                navItem.title === access.access_name
                              ) {
                                navItem.subNav.forEach((subnav) => {
                                  if (
                                    i == 0 &&
                                    subnav.title == access.access_name_option
                                  ) {
                                    landingPath = subnav.path;
                                  }
                                });
                              } else if (navItem.title === access.access_name) {
                                landingPath = navItem.path;
                              }
                            });
                          });
                        } else {
                          landingPath = `/sandbox`;
                        }
                        setUser(user);
                        console.log("In")
                        window.location = landingPath;
                      } else {
                        //deny entry
                        // alert(res);
                        alert("Account does not exist.");
                        this.close();
                      }
                    })
                    .catch((err) => {
                      alert(`Error ${err}`);
                    });
                } catch (err) {
                  console.log(err);
                }
              })
              .catch((err) => {
                alert(`Error: ${err}`);
              });
          } else {
            alert("Error");
          }
        })
        .catch((e) => {
          // this.setState({disabledProceed: true})
          // alert("Invalid OTP. Please try again. " + err)
          alert(e);
        });
    } else {
      alert("Please enter 6 digits OTP number.");
    }
  }
  sendCode() {
    window.appVerifier = new RecaptchaVerifier(
      getAuth(),
      "recaptcha-container",
      {
        size: "invisible",
      }
    );
    const appVerifier = window.appVerifier;
    this.setState({
      mobile: this.props.mobile,
      disabled: false,
    });
    var number = "+65 " + this.props.mobile;
    console.log(number);
    signInWithPhoneNumber(getAuth(), number, appVerifier)
      .then(function (confirmationResult) {
        console.log("Success");
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        console.log(confirmationResult);
        alert("OTP sent");
      })
      .catch(function (error) {
        alert(error);
        console.log("Error:" + error);
      });
  }
  handleOTPChange(event) {
    this.setState({ otp: event.target.value, disabled: false });
  }
  componentDidMount() {
    initializeApp(firebaseConfig);
    const getEnvVariable = (key) => process.env[key];
    this.setState({ 
      ssoLogin: commonConfigs.apiUrls.ssoAdminAuth(),
      userLogin: getEnvVariable(`REACT_APP_BASE_${this.props.resourceDomain.toUpperCase()}_STAGING_URL`) + commonConfigs.apiUrls.login(),
      ssoHeader: {
        "api-key": getEnvVariable(`REACT_APP_SSO_${this.props.resourceDomain.toUpperCase()}_STAGING_API_KEY`),
        "project-id": getEnvVariable(`REACT_APP_SSO_${this.props.resourceDomain.toUpperCase()}_STAGING_PROJECT_ID`),
      },
      userHeader: {
        "api-key": getEnvVariable(`REACT_APP_RESOURCE_${this.props.resourceDomain.toUpperCase()}_API_KEY`),
        "project-id": getEnvVariable(`REACT_APP_RESOURCE_${this.props.resourceDomain.toUpperCase()}_PROJECT_ID`),
      },
      orgId: getEnvVariable(`REACT_APP_RESOURCE_${this.props.resourceDomain.toUpperCase()}_ORG_ID`)
    });
  }
  render() {
    return (
      <>
        <Transition.Root show={this.isOpen()} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={() => this.close()}
          >
            <div className="flex items-center justify-center min-h-screen">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay
                  className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity"
                  onClick={() => this.close()}
                />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <form
                    className="bg-white rounded px-8 pt-6 pb-8 mb-4"
                    onSubmit={this.handleSubmit}
                  >
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3 mt-5">
                        <label
                          htmlFor="mobile"
                          className="block text-sm font-medium text-gray-700"
                          style={{ marginTop: "-50px" }}
                        >
                          Mobile Number *
                        </label>
                        <input
                          type="text"
                          name="mobile"
                          id="mobile"
                          placeholder="Enter Mobile Number"
                          style={{ color: "black" }}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          value={this.props.mobile}
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3 self-end">
                        <label
                          htmlFor="button"
                          className="block text-sm font-medium text-gray-700"
                        ></label>
                        <button
                          type="button"
                          className="w-full justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={() => this.sendCode()}
                          style={{
                            backgroundColor:
                              "rgb(59 130 246 / var(--tw-bg-opacity))",
                          }}
                        >
                          Get SMS OTP
                        </button>
                      </div>
                    </div>

                    <div className="col-span-6 mt-5">
                      <label
                        htmlFor="otp"
                        className="block text-sm font-medium text-gray-700"
                        style={{ marginTop: "-30px" }}
                      >
                        SMS OTP
                      </label>
                      <input
                        type="password"
                        name="otp"
                        id="otp"
                        placeholder="Enter SMS OTP"
                        style={{ color: "black" }}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        value={this.state.otp}
                        onChange={this.handleOTPChange}
                        disabled={this.state.disabled}
                      />
                    </div>

                    <div className="flex items-center justify-between mt-5">
                      <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={this.handleSubmit}
                        style={{
                          marginTop: "-20px",
                          marginBottom: "-30px",
                          backgroundColor:
                            "rgb(59 130 246 / var(--tw-bg-opacity))",
                        }}
                      >
                        Proceed
                      </button>
                    </div>
                  </form>
                  <div id="recaptcha-container" />
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

// export default OtpModal;
