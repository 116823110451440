import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { commonConfigs, urls } from "../../../config/config";
import Card from './detailsCard';

const ProjectDetails = () => {
  const location = useLocation();
  const { developerSsoId, developerProjId, projectIndex, environment } = location.state || {};
  const [load, setLoad] = useState(true);
  const [data, setData] = useState([]);
  let getOneSandboxUrl = `${commonConfigs.apiUrls.GetOneDeveloperProjects(developerSsoId, developerProjId)}`;
  if (environment === "staging") {
    getOneSandboxUrl = `${urls.baseUrl}${getOneSandboxUrl}`
  }
  else {
    getOneSandboxUrl = `${urls.prodUrl}${getOneSandboxUrl}`
  }
  
  async function getProject() {
    await axios
    .get(getOneSandboxUrl, { headers: commonConfigs.apiHeader })
    .then((res) => {
      console.log(res.data.result.data);
      setData(res.data.result.data);
      setLoad(false);
    })
    .catch((err) => {
      console.log(err);
  })}

  useEffect(() => { 
    getProject();
  }, []);
 
  if (!load) {
    return (
      <div>
        <div className="mt-4 desktop:space-x-8  mobile:pt-2  mobile:pb-4 mobile:space-x-2">
          {/* calling the Card component */}
          <div className="mt-16 flex flex-grow gap-x-1 justify-end pr-10" style={{display: 'grid', gridTemplateColumns: '2fr 2fr 2fr'}}>
            <Card data={data} index = {projectIndex} environment={environment} />
          </div>
        </div>
      </div>
    );
  }
};

export default ProjectDetails;