import axios from "axios";
import { InfoCircle } from "iconsax-react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Select from "react-select";
import { commonConfigs, urls } from "../../../config/config";

const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
  </div>
);

const user = JSON.parse(localStorage.getItem("user"));

export default function CreateAccessRole() {
  const [isDisabled, setIsDisabled] = useState(false);
  const [accessOptions, setAccessOptions] = useState([]);
  const [loadData, setLoadData] = useState(true);
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({});
  let getAllAccessPagesUrl = ``;
  let createAccessRolesUrl = ``;
  let apiHeaders;

  if (process.env.REACT_APP_ENV === "staging") {
    createAccessRolesUrl =
      urls.baseUrl +
      commonConfigs.apiUrls.createAccessRole();
    getAllAccessPagesUrl =
      urls.baseUrl +
      commonConfigs.apiUrls.getAllAccessPages();
    apiHeaders = commonConfigs.apiHeader;
  } else {
    createAccessRolesUrl =
      urls.prodUrl +
      commonConfigs.apiUrls.createAccessRole();
    getAllAccessPagesUrl =
      urls.prodUrl +
      commonConfigs.apiUrls.getAllAccessPages();
    apiHeaders = commonConfigs.prodApiHeader;
  }

  function formatData(data) {
    var array = [];

    if (data.access_rights.length > 0) {
      data.access_rights.forEach((element) => {
        array.push(element.value);
      });
    }

    var newData = data;
    newData.access_rights = array;
    newData.updated_by = null;
    return newData;
  }

  const onSubmit = (data) => {
    setIsDisabled(true); // Disable the submit button

    formatData(data);
    console.log(data);
    axios
      .post(createAccessRolesUrl, data, { headers: apiHeaders })
      .then((res) => {
        try {
          if (res.data.status !== "success") {
            alert("danger");
          } else {
            alert("success");
          }
        } catch (err) {
          console.log(err);
        } finally {
          setIsDisabled(false); // Enable the submit button after API call
          window.location.href = `/accessroles`;
        }
      })
      .catch((err) => {
        alert(`Error: ${err}`);
        setIsDisabled(false); // Enable the submit button if there's an error
      });
  };

  useEffect(() => {
    async function getAccessPages() {
      await axios
        .get(getAllAccessPagesUrl, { headers: apiHeaders })
        .then((res) => {
          var finalAccess = [];

          var response = res.data.result.data;
          console.log(response);

          for (var i = 0; i < response.length; i++) {
            if (response[i].access_name_option !== null) {
              finalAccess.push({
                value: response[i].access_id,
                label:
                  response[i].access_name +
                  " [" +
                  response[i].access_name_option +
                  "]",
              });
            } else {
              finalAccess.push({
                value: response[i].access_id,
                label: response[i].access_name,
              });
            }
          }
          setAccessOptions(finalAccess);
          setLoadData(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (loadData) {
      getAccessPages().catch((err) => {
        console.log("err for access types: " + err);
      });
    }
  }, []);

  if (!loadData) {
    return (
      <div>
        <div
          className="mt-16 desktop:space-x-8  mobile:pt-2  mobile:pb-4 mobile:space-x-2"
          style={{ textAlign: "-webkit-center" }}
        >
          <div
            className="bg-white border-white border rounded-2xl m-5 p-4"
            style={{
              height: "inherit",
              width: "65%",
              minHeight: 0,
              minWidth: 0,
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-2">
                <div className="mt-2">
                  <label
                    htmlFor="role_name"
                    className="block text-sm font-medium text-gray-600 mt-8"
                    style={{ textAlign: "left", marginBottom: "5px" }}
                  >
                    Role Name
                  </label>
                  <div className="relative rounded-md shadow-sm text-black">
                    <input
                      type="text"
                      name="role_name"
                      id="role_name"
                      className={
                        !errors.role_name
                          ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                          : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                      }
                      placeholder="Enter Name of Role"
                      {...register("role_name", { required: true })}
                    />
                    {errors.role_name?.type === "required" && errorIcon}
                  </div>
                </div>
              </div>

              <label
                htmlFor="access_rights"
                className="block text-sm font-medium text-gray-600 mt-8"
                style={{ textAlign: "left", marginBottom: "5px" }}
              >
                Access Types
              </label>
              <div className="sm:col-span-2 mb-8 text-black">
                <Controller
                  name="access_rights"
                  control={control}
                  render={({ field }) => (
                    <Select
                      isClearable
                      {...field}
                      options={accessOptions}
                      isSearchable={true}
                      isMulti={true}
                      placeholder="Select at least one option"
                      className="text-sm"
                    />
                  )}
                />
              </div>

              {/* create button */}
              <div style={{ alignItems: "center" }}>
                <Link to="/accessroles">
                  <button
                    type="button"
                    className="bg-white text-red-500 hover:bg-red-500 hover:text-white outline font-bold py-2 px-12 rounded-full ml-5 mt-10"
                    disabled={isDisabled}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "red";
                      e.target.style.color = "#ffffff";
                      e.target.style.setProperty(
                        "background-color",
                        "red",
                        "important"
                      );
                      e.target.style.setProperty(
                        "color",
                        "#ffffff",
                        "important"
                      );
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#ffffff";
                      e.target.style.color = "red";
                      e.target.style.setProperty(
                        "background-color",
                        "#ffffff",
                        "important"
                      );
                      e.target.style.setProperty("color", "red", "important");
                    }}
                  >
                    Cancel
                  </button>
                </Link>
                <button
                  type="submit"
                  className="bg-white text-blue-500 hover:bg-blue-500 hover:text-white outline font-bold py-2 px-12 rounded-full ml-5 mt-10"
                  disabled={isDisabled}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "#3b82f6";
                    e.target.style.color = "#ffffff";
                    e.target.style.setProperty(
                      "background-color",
                      "#3b82f6",
                      "important"
                    );
                    e.target.style.setProperty("color", "#ffffff", "important");
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#ffffff";
                    e.target.style.color = "#3b82f6";
                    e.target.style.setProperty(
                      "background-color",
                      "#ffffff",
                      "important"
                    );
                    e.target.style.setProperty("color", "#3b82f6", "important");
                  }}
                >
                  Create
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
