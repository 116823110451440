import axios from "axios";
import { InfoCircle } from "iconsax-react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { TagsInput } from "react-tag-input-component";
import { commonConfigs, urls } from "../../../config/config";

const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
  </div>
);

export default function CreateAccessPage() {
  const [isDisabled, setIsDisabled] = useState(false);
  const [accessName, setAccessName] = useState();
  const [accessNameOption, setAccessNameOption] = useState();
  const [load, setLoad] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({});
  let createAccessPagesUrl = ``;
  let apiHeaders;

  if (process.env.REACT_APP_ENV === "staging") {
    createAccessPagesUrl =
      urls.baseUrl +
      commonConfigs.apiUrls.createAccessPage();
    apiHeaders = commonConfigs.apiHeader;
  } else {
    createAccessPagesUrl =
      urls.prodUrl +
      commonConfigs.apiUrls.createAccessPage();
    apiHeaders = commonConfigs.prodApiHeader;
  }

  console.log(createAccessPagesUrl, apiHeaders)

  function formatData(data) {
    var newData = data;
    newData.access_name = data.access_name;
    newData.access_name_options = data.access_name_options
      ? data.access_name_options
      : [];
    return newData;
  }

  const onSubmit = (data) => {
    setIsDisabled(true); // Disable the submit button

    formatData(data);
    // console.log(data);
    axios
      .post(createAccessPagesUrl, data, { headers: apiHeaders })
      .then((res) => {
        try {
          if (res.data.status !== "success") {
            alert("danger");
          } else {
            alert("success");
          }
        } catch (err) {
          console.log(err);
        } finally {
          setIsDisabled(false); // Enable the submit button after API call
          window.location.href = `/accesspages`;
        }
      })
      .catch((err) => {
        alert(`Error: ${err}`);
        setIsDisabled(false); // Enable the submit button if there's an error
      });
  };

  if (!load) {
    return (
      <div>
        <div
          className="mt-16 desktop:space-x-8  mobile:pt-2  mobile:pb-4 mobile:space-x-2"
          style={{ textAlign: "-webkit-center" }}
        >
          <div
            className="bg-white border-white border rounded-2xl m-5 p-4"
            style={{
              height: "inherit",
              width: "65%",
              minHeight: 0,
              minWidth: 0,
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-2">
                <div className="mt-2">
                  <label
                    htmlFor="access_name"
                    className="block text-sm font-medium text-gray-600 mt-8"
                    style={{ textAlign: "left", marginBottom: "5px" }}
                  >
                    Access Name
                  </label>
                  <div className="relative rounded-md shadow-sm text-black">
                    <input
                      type="text"
                      name="access_name"
                      id="access_name"
                      className={
                        !errors.access_name
                          ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                          : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                      }
                      placeholder="Enter Name of Access"
                      {...register("access_name", { required: true })}
                      value={accessName}
                      onChange={(e) => setAccessName(e.target.value)}
                    />
                    {errors.accessName?.type === "required" && errorIcon}
                  </div>
                </div>
              </div>

              <label
                htmlFor="accessNameOption"
                className="block text-sm font-medium text-gray-600 mt-8"
                style={{ textAlign: "left", marginBottom: "5px" }}
              >
                Access Name Option
              </label>
              <div className="relative rounded-md shadow-sm text-black">
                <Controller
                  name="access_name_options"
                  control={control}
                  render={({ field }) => (
                    <TagsInput
                      value={field.value}
                      onChange={field.onChange}
                      name="access_name_options"
                      placeHolder="Enter Access Name Option"
                    />
                  )}
                />
              </div>

              {/* create button */}
              <div style={{ alignItems: "center" }}>
                <Link to="/accessroles">
                  <button
                    type="button"
                    className="bg-white text-red-500 hover:bg-red-500 hover:text-white outline font-bold py-2 px-12 rounded-full ml-5 mt-10"
                    disabled={isDisabled}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "red";
                      e.target.style.color = "#ffffff";
                      e.target.style.setProperty(
                        "background-color",
                        "red",
                        "important"
                      );
                      e.target.style.setProperty(
                        "color",
                        "#ffffff",
                        "important"
                      );
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "#ffffff";
                      e.target.style.color = "red";
                      e.target.style.setProperty(
                        "background-color",
                        "#ffffff",
                        "important"
                      );
                      e.target.style.setProperty("color", "red", "important");
                    }}
                  >
                    Cancel
                  </button>
                </Link>
                <button
                  type="submit"
                  className="bg-white text-blue-500 hover:bg-blue-500 hover:text-white outline font-bold py-2 px-12 rounded-full ml-5 mt-10"
                  disabled={isDisabled}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "#3b82f6";
                    e.target.style.color = "#ffffff";
                    e.target.style.setProperty(
                      "background-color",
                      "#3b82f6",
                      "important"
                    );
                    e.target.style.setProperty("color", "#ffffff", "important");
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#ffffff";
                    e.target.style.color = "#3b82f6";
                    e.target.style.setProperty(
                      "background-color",
                      "#ffffff",
                      "important"
                    );
                    e.target.style.setProperty("color", "#3b82f6", "important");
                  }}
                >
                  Create
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
