export const ssoUrl = "https://stg.sso.openapipass.org/api/v1";
export const redirectUrl = "https://stg-foodcanopy-web.azurewebsites.net/login";
const resourceDomain = localStorage.getItem('resourceDomain');
const getEnvVariable = (key) => process.env[key];

let urls = {
  baseUrl: getEnvVariable(`REACT_APP_BASE_${resourceDomain?.toUpperCase()}_STAGING_URL`),
  prodUrl: getEnvVariable(`REACT_APP_BASE_${resourceDomain?.toUpperCase()}_PRODUCTION_URL`),
  sandboxUrl: getEnvVariable(`REACT_APP_BASE_${resourceDomain?.toUpperCase()}_SANDBOX_URL`),
}

let commonConfigs = {
  redirectUrl: redirectUrl,
  ssoHeader: {
    "api-key": getEnvVariable(`REACT_APP_SSO_${resourceDomain?.toUpperCase()}_STAGING_API_KEY`),
    "project-id": getEnvVariable(`REACT_APP_SSO_${resourceDomain?.toUpperCase()}_STAGING_PROJECT_ID`),
    "platform-syscode": process.env.REACT_APP_PLATFORM_TYPE
  },
  apiHeader: {
    "api-key": getEnvVariable(`REACT_APP_DEVELOPER_${resourceDomain?.toUpperCase()}_STAGING_API_KEY`),
    "project-id": getEnvVariable(`REACT_APP_DEVELOPER_${resourceDomain?.toUpperCase()}_STAGING_PROJECT_ID`),
    "platform-syscode": process.env.REACT_APP_PLATFORM_TYPE
  },
  sandboxApiHeader: {
    "api-key": getEnvVariable(`REACT_APP_DEVELOPER_${resourceDomain?.toUpperCase()}_SANDBOX_API_KEY`),
    "project-id": getEnvVariable(`REACT_APP_DEVELOPER_${resourceDomain?.toUpperCase()}_SANDBOX_PROJECT_ID`),
    "platform-syscode": process.env.REACT_APP_PLATFORM_TYPE
  },
  prodApiHeader: {
    "api-key": getEnvVariable(`REACT_APP_DEVELOPER_${resourceDomain?.toUpperCase()}_PRODUCTION_API_KEY`),
    "project-id": getEnvVariable(`REACT_APP_DEVELOPER_${resourceDomain?.toUpperCase()}_PRODUCTION_PROJECT_ID`),
    "platform-syscode": process.env.REACT_APP_PLATFORM_TYPE
  },
  resourceOwnerApiHeader: {
    "api-key": getEnvVariable(`REACT_APP_RESOURCE_${resourceDomain?.toUpperCase()}_API_KEY`),
    "project-id": getEnvVariable(`REACT_APP_RESOURCE_${resourceDomain?.toUpperCase()}_PROJECT_ID`),
    "platform-syscode": process.env.REACT_APP_PLATFORM_TYPE
  },

  apiUrls: {
    ssoUserAuth: () => `${ssoUrl}/auth/users`,
    ssoAdminAuth: () => `${ssoUrl}/auth/business`,
    login: () => `/api/v1/web/auth/login`,

    //Staging Developer Projects Apis
    GetAllDeveloperProjects: (developer_sso_id) =>
      `/api/v1/developers/${developer_sso_id}/projects`,
    GetOneDeveloperProjects: (developer_sso_id, developer_proj_id) =>
      `/api/v1/developers/${developer_sso_id}/projects/${developer_proj_id}`,
    CreateDeveloperProject: () => `/api/v1/developers/projects`,
    UpdateDeveloperProject: (developer_proj_id) =>
      `/api/v1/developers/projects/${developer_proj_id}`,
    getProjects: () => `/api/v1/resource-group/projects`,

    //Portfolio Apis
    getAllPortfolioProjects: (mobileNum) =>
      `/api/v1/developers/portfolios/mobile/${mobileNum}`,

    // Access Pages Apis
    getAllAccessPages: () =>
      `/api/v1/developers/access`,
    createAccessPage: () =>
      `/api/v1/developers/access`,
    updateAccessPage: (access_id) =>
      `/api/v1/developers/access/${access_id}`,
    deleteAccessPage: (access_id) =>
      `/api/v1/developers/access/${access_id}`,

    // Access Roles Apis
    getAllAccessRoles: () => `/api/v1/developers/roles`,
    createAccessRole: () => `/api/v1/developers/roles`,
    updateAccessRole: (role_id) => `/api/v1/developers/roles/${role_id}`,
    deleteAccessRole: (role_id) => `/api/v1/developers/roles/${role_id}`,

    // Configurations Apis
    // GetAllBillingConfig: () =>
    //   `${beveatUrl}/resource-groups/projects/billing-config`,
    // // `http://localhost:8080/api/v1/resource-groups/projects/billing-config`,
    // GetOneBillingConfig: (billing_config_id) =>
    //   `${beveatUrl}/resource-groups/projects/billing-config/${billing_config_id}`,
    // CreateBillingConfig: () =>
    //   `${beveatUrl}/resource-groups/projects/billing-config`,
    // UpdateBillingConfig: (billing_config_id) =>
    //   `${beveatUrl}/resource-groups/projects/billing-config/${billing_config_id}`,
    // DeleteBillingConfig: (billing_config_id) =>
    //   `${beveatUrl}/resource-groups/projects/billing-config/${billing_config_id}`,

    // GetAllSubscriptionPlan: () => `${beveatUrl}/resource-groups/subscriptions`,
    // // `http://localhost:8080/api/v1/resource-groups/subscriptions`,
    // GetOneSubscriptionPlan: (subscription_id) =>
    //   `${beveatUrl}/resource-groups/subscriptions/${subscription_id}`,
    // CreateSubscriptionPlan: () => `${beveatUrl}/resource-groups/subscriptions`,
    // UpdateSubscriptionPlan: (subscription_id) =>
    //   `${beveatUrl}/resource-groups/subscriptions/${subscription_id}`,
    // DeleteSubscriptionPlan: (subscription_id) =>
    //   `${beveatUrl}/resource-groups/subscriptions/${subscription_id}`,
  },
};

export { urls, commonConfigs };