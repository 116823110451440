import axios from "axios";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import { Checkbox, Typography } from "@material-tailwind/react";
import { urls, commonConfigs } from "../../../config/config";
// import commonConfigs, { urls } from "../../../config/config";
import { InfoCircle } from "iconsax-react";

const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
  </div>
);

const CreateProject = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [optionLoad, setOptionLoad] = useState(true);
  const [projects, setProjectsData] = useState([]);
  const [developerProjName, setDeveloperProjName] = useState();
  const [projUrl, setProjUrl] = useState();
  const [environment, setEnvironment] = useState();
  const [stagingProjects, setStagingProjects] = useState([]);
  const [productionProjects, setProductionProjects] = useState([]);
  const [apiOptions, setApiOptions] = useState([]);
  const [load, setLoad] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      subscription_id: null
    },
  });

  const createStagingProjectUrl = urls.baseUrl + commonConfigs.apiUrls.CreateDeveloperProject()
  const createProductionProjectUrl = urls.prodUrl + commonConfigs.apiUrls.CreateDeveloperProject()
  const getStagingProjectsUrl = urls.baseUrl + commonConfigs.apiUrls.getProjects()
  const getProductionProjectsUrl = urls.prodUrl + commonConfigs.apiUrls.getProjects()

  useEffect(() => {
    async function getStagingProjectsData() {
      await axios
        .get(getStagingProjectsUrl, { headers: commonConfigs.apiHeader })
        .then((res) => {
          console.log(res.data.result.data)
          setStagingProjects(res.data.result.data);
          setOptionLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    async function getProductionProjectsData() {
      await axios
        .get(getProductionProjectsUrl, { headers: commonConfigs.prodApiHeader })
        .then((res) => {
          console.log(res.data.result.data)
          setProductionProjects(res.data.result.data);
          setOptionLoad(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (optionLoad) {
      getStagingProjectsData().catch((err) => {
        console.log("err for staging projects : " + err);
      });
      getProductionProjectsData().catch((err) => {
        console.log("err for production projects : " + err);
      });
    }
  }, []);

  useEffect(() => {
    if (environment) {
      if (environment === "0") {
        setProjectsData(stagingProjects);
      } else if (environment === "1") {
        setProjectsData(productionProjects);
      } else {
        setProjectsData(null);
      }
    }
  }, [environment, stagingProjects, productionProjects]);

  useEffect(()=> {
    if(projects) {
    console.log(projects)
    const options = projects
    .filter((project) => project.project_billing_config_id == 1)
    .map((project) => ({
      value: project.project_id,
      label: project.project_name
    }))
    setApiOptions(options);
    }
  }, [projects])

  const options = [
    { value: "0", label: "Staging" },
    { value: "1", label: "Production" }
  ];
  
  const options_plan = [
    { value: null, label: "None" },
    { value: '1', label: "Subscription 1" },
    { value: '2', label: "Subscription 2" },
  ];

  function formatData(data) {
    var newData = data;
    var developerSsoId = localStorage.getItem("ssoId");
    var subscriptionId = data.subscription_id.value;

    if (data.access) {
      var access = [];
      if (data.access.length > 0) {
        access = data.access.map(item => ({ project_id: item.value }));
      }
    } else {
      access = null;
    }
    console.log("formatdata", newData);
    newData.developer_sso_id = developerSsoId;
    newData.developer_proj_name = developerProjName;
    newData.subscription_id = subscriptionId;
    newData.access = access;
    return newData;
  }

  const onSubmit = async (data) => {
    setIsDisabled(true); // Disable the submit button

    const newData = formatData(data);
    console.log(data)
    console.log(environment)
    console.log("formatted data", newData);

    console.log("newData", newData);

    if(environment == 0) {
      axios
        .post(createStagingProjectUrl, newData, {
          headers: commonConfigs.apiHeader,
        })
        .then((res) => {
          try {
            if (res.data.status !== "success") {
              alert("danger");
            } else {
              alert("success");
            }
          } catch (err) {
            console.log(err);
          } finally {
            setIsDisabled(false); // Enable the submit button after API call
            window.location.href = '/projects'
          }
        })
        .catch((err) => {
          alert(`Error: ${err}`);
          setIsDisabled(false); // Enable the submit button if there's an error
        });
    } 
    else if (environment == 1) {
      axios
        .post(createProductionProjectUrl, newData, {
          headers: commonConfigs.prodApiHeader,
        })
        .then((res) => {
          try {
            if (res.data.status !== "success") {
              alert("danger");
            } else {
              alert("success");
              // window.location.href = '/projects'
            }
          } catch (err) {
            console.log(err);
          } finally {
            setIsDisabled(false); // Enable the submit button after API call
            load(true);
          }
        })
        .catch((err) => {
          const err_msg = JSON.stringify(err.response.data.result.message);
          alert(`Error code : ${err.response.status} , ${err_msg}`);
          setIsDisabled(false); // Enable the submit button if there's an error
        });
    }
  };

  if (!load) {
    return (
      <div>
        <div className="mt-16 desktop:space-x-8  mobile:pt-2  mobile:pb-4 mobile:space-x-2" style={{ textAlign: "-webkit-center", marginTop: "-2px" }}>
          <div className="bg-white border-white border rounded-2xl m-5 p-4" style={{height: "inherit", width: "65%", minHeight: 0, minWidth: 0}}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-2">
                <div className="mt-2">
                <label htmlFor="developer_proj_name" className="block text-sm font-medium text-gray-600 mt-8" style={{ textAlign: "left", marginBottom: "5px" }}>
                  Project Name
                </label>
                      <div className="relative rounded-md shadow-sm" style = {{color: "black"}}>
                        <input
                          type="text"
                          name="developer_proj_name"
                          id="developer_proj_name"
                          className={
                            !errors.developer_proj_name
                              ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder="Enter Name of Project"
                          {...register("developer_proj_name", { required: true })}
                          value={developerProjName}
                          onChange={(e) => setDeveloperProjName(e.target.value)}
                        />
                        {errors.developer_proj_name?.type === "required" && errorIcon}
                      </div>
                    </div>
              </div>
              <div className="mt-8 sm:col-span-2 mb-8" style={{color: "black"}}>
                <Controller
                  name="environment"
                  control={control}
                  render={({ field }) => (
                    <Select
                      isClearable
                      {...field}
                      options={options}
                      isSearchable={true}
                      placeholder="Select Environment"
                      className="text-sm"
                      onChange={(selectedOption) => {
                        console.log(selectedOption)
                        if(selectedOption) {
                          setEnvironment(selectedOption.value); 
                        }
                      }}
                    />
                  )}
                />
              </div>
              <div className="mt-8 sm:col-span-2 mb-8" style={{color: "black"}}>
                <Controller
                  name="access"
                  control={control}
                  render={({ field }) => (
                    <Select
                      isClearable
                      {...field}
                      options={apiOptions}
                      isSearchable={true}
                      isMulti={true}
                      placeholder="Select API"
                      className="text-sm"
                    />
                  )}
                />
              </div>
              <label
                htmlFor="developer_proj_website_url"
                className="block text-sm font-medium text-black"
                style={{ textAlign: "left", marginBottom: "5px", marginTop: "-10px" }}
              >
                Project Website URL
              </label>
              <div className="relative rounded-md shadow-sm mb-2 text-black">
                <input
                  type="text"
                  name="developer_proj_website_url"
                  id="developer_proj_website_url"
                  className={
                    !errors.developer_proj_website_url
                      ? "block w-full pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                      : "block w-full pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                  }
                  placeholder="Enter Project Website URL"
                  {...register("developer_proj_website_url", { required: true })}
                  style={{marginBottom: "20px"}}
                />
                {errors.developer_proj_website_url?.type === "required" && errorIcon}
              </div>

              <div style={{ textAlign: "left", color: "black" }}>
                No Subscription plan? Create one
                <Link to="/projects">
                  <button
                    type="button"
                    className="bg-white hover:bg-white text-blue-500 rounded-full mr-6 ml-1"
                  >
                    here
                  </button>
                </Link>
              </div>

              <div className="mt-1 sm:col-span-2 mb-8" style={{color: "black"}}>
                <Controller
                  name="subscription_id"
                  control={control}
                  render={({ field }) => (
                    <Select
                      isClearable
                      {...field}
                      options={options_plan}
                      isSearchable={true}
                      placeholder="Select Subscription Plan"
                      className="text-sm"
                    />
                  )}
                />
              </div>

              {/* checkbox */}
              {/* <div style={{ textAlign: "left", marginLeft: "-1.8%", marginTop: "-20px" }}>
                <Checkbox
                  className="border-black border-1"
                  label={
                    <div>
                      <Typography
                        variant="small"
                        color="gray"
                        className="font-normal"
                        style={{ marginLeft: "-3px" }}
                      >
                        I grant permission for this project to be made and
                        readily used
                      </Typography>
                    </div>
                  }
                  required={true}
                />
              </div> */}

              {/* create button */}
              <div>
                <Link to="/projects">
                  <button
                    type="button"
                    className="bg-white text-red-500 hover:bg-red-500 hover:text-white outline font-bold py-2 px-12 rounded-full ml-5 mt-10"
                    disabled={isDisabled}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = 'red';
                      e.target.style.color = '#ffffff';
                      e.target.style.setProperty('background-color', 'red', 'important');
                      e.target.style.setProperty('color', '#ffffff', 'important');
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = '#ffffff';
                      e.target.style.color = 'red';
                      e.target.style.setProperty('background-color', '#ffffff', 'important');
                      e.target.style.setProperty('color', 'red', 'important');
                    }}
                  >
                    Cancel
                  </button>
                </Link>
                  <button
                    type="submit"
                    className="bg-white text-blue-500 hover:bg-blue-500 hover:text-white outline font-bold py-2 px-12 rounded-full ml-5 mt-10"
                    disabled={isDisabled}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = '#3b82f6';
                      e.target.style.color = '#ffffff';
                      e.target.style.setProperty('background-color', '#3b82f6', 'important');
                      e.target.style.setProperty('color', '#ffffff', 'important');
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = '#ffffff';
                      e.target.style.color = '#3b82f6';
                      e.target.style.setProperty('background-color', '#ffffff', 'important');
                      e.target.style.setProperty('color', '#3b82f6', 'important');
                    }}
                  >
                    Create
                  </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
};

export default CreateProject;