import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Card from "../components/projectCard";
import Spinner from "../../../commons/components/spinner";
import { projectsCurrentTabContext } from "../context/projectsContext";
import ProjectsMenuTabs from "../components/projectsMenuTabs";
import { urls, commonConfigs } from "../../../config/config";
// import commonConfigs, { urls } from "../../../config/config";

const ProjectPageLayout = () => {
  const { currentTab } = useContext(projectsCurrentTabContext);
  const [load, setLoad] = useState(true);
  const [stagingData, setStagingData] = useState([]);
  const [productionData, setProductionData] = useState([]);
  const ssoId = localStorage.getItem("ssoId");
  const getAllStagingDeveloperProjectsUrl =
    urls.baseUrl +
    commonConfigs.apiUrls.GetAllDeveloperProjects(ssoId);
  const getAllProductionDeveloperProjectsUrl =
    urls.prodUrl +
    commonConfigs.apiUrls.GetAllDeveloperProjects(ssoId);
  async function getStagingDeveloperProjects() {
    await axios
      .get(getAllStagingDeveloperProjectsUrl, {
        headers: commonConfigs.apiHeader,
      })
      .then((res) => {
        console.log(res.data.result.data);
        setStagingData(res.data.result.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getProductionDeveloperProjects() {
    await axios
      .get(getAllProductionDeveloperProjectsUrl, {
        headers: commonConfigs.apiHeader,
      })
      .then((res) => {
        console.log(res.data.result.data);
        setProductionData(res.data.result.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getStagingDeveloperProjects();
    getProductionDeveloperProjects();
  }, []);

  if (!load) {
    return (
      <div style={{ width: "100%" }}>
        <div className="mt-4 desktop:space-x-8  mobile:pt-2  mobile:pb-4 mobile:space-x-2">
          {/* button for 'Create Project' */}
          <Link to="/projects/create">
            <button
              type="button"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2.5 px-14 rounded-full mr-10"
              style={{ float: "right" }}
            >
              Create New Project
            </button>
          </Link>
          <div>
            <div className="flex-1 min-w-0 mobile:pt-4 mobile: ml-2" style={{marginBottom: "-20px"}}>
              <ProjectsMenuTabs />
            </div>
            {currentTab.name == "Staging" && (
              <>
                {stagingData != null ? (
                  <div
                    className="mt-10 flex flex-grow gap-x-2 justify-end pr-10"
                    style={{
                      display: "grid",
                      gridTemplateColumns: "2fr 2fr 2fr",
                    }}
                  >
                    {stagingData.map((item, index) => (
                      <Card
                        key={index}
                        data={item}
                        index={index}
                        environment="staging"
                      />
                    ))}
                  </div>
                ) : (
                  <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
                    <div className="flex place-content-center w-full">
                      <Spinner className="w-20 h-20" />
                    </div>
                  </div>
                )}
              </>
            )}
            {currentTab.name == "Production" && (
              <>
                {productionData != null ? (
                  <div
                    className="mt-10 flex flex-grow gap-x-2 justify-end pr-10"
                    style={{
                      display: "grid",
                      gridTemplateColumns: "2fr 2fr 2fr",
                    }}
                  >
                    {productionData.map((item, index) => (
                      <Card
                        key={index}
                        data={item}
                        index={index}
                        environment="production"
                      />
                    ))}
                  </div>
                ) : (
                  <div>
                    <div className="flex place-content-center w-full">
                      <Spinner className="w-20 h-20" />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default ProjectPageLayout;
