import { ArrowDown2, ArrowUp2 } from "iconsax-react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { classNames } from "../utils/helper";

const SidebarItem = ({ item, callback, selectedTitle }) => {
  const [showSubItems, setShowSubItems] = useState(false);
  return (
    <>
      {" "}
      <Link
        key={item.title}
        to={item.path}
        onClick={() => {
          if (!item.subNav) {
            callback(item.title);
          } else {
            setShowSubItems(!showSubItems);
          }
        }}
        className={classNames(
          item.title == selectedTitle
            ? "text-gray-800 bg-white"
            : "text-white hover:bg-gray-800 hover:text-gray-400",
          "group flex items-center my-1 px-2 py-2 text-base font-medium rounded-md justify-between"
        )}
      >
        <div className="flex">
          <item.icon
            className={classNames(
              item.title == selectedTitle
                ? "text-gray-800"
                : "text-white group-hover:text-gray-400",
              "mr-4 flex-shrink-0 h-6 w-6"
            )}
            aria-hidden="true"
          />
          {item.title}
        </div>
        {item.subNav &&
          (showSubItems ? (
            <ArrowUp2
              className={classNames(
                item.title == selectedTitle
                  ? "text-gray-800"
                  : "text-white group-hover:text-gray-400",
                "mr-4 flex-shrink-0 h-6 w-6"
              )}
              aria-hidden="true"
            />
          ) : (
            <ArrowDown2
              className={classNames(
                item.title == selectedTitle
                  ? "text-gray-800"
                  : "text-white group-hover:text-gray-400",
                "mr-4 flex-shrink-0 h-6 w-6"
              )}
              aria-hidden="true"
            />
          ))}
      </Link>
      {showSubItems &&
        item.subNav.map((item, index) => {
          return (
            <Link
              to={item.path}
              key={index}
              onClick={() => callback(item.title)}
              className={classNames(
                item.title == selectedTitle
                  ? "text-gray-800 bg-white"
                  : "text-white hover:bg-gray-800 hover:text-gray-400",
                "group flex items-center my-1 px-2 py-2 text-base font-medium rounded-md pl-8"
              )}
            >
              {item.title}
            </Link>
          );
        })}
    </>
  );
};

export default SidebarItem;
