import { createContext, useState } from "react";
import {
  Building, Setting2,
} from "iconsax-react";
import navigation from "./constants/navRoutes";
import { getUser } from "../../services/tokenService"

const sidebarAccesses = getUser()?.access;
var accessArr = [];

if (sidebarAccesses) {
  navigation.forEach(navItem => {
    // Find access for the main navigation item
    const access = sidebarAccesses.find(acc =>
      acc.access_name === navItem.title &&
      acc.access_name_option === null
    );
    if (access) {
      navItem.access_id = access.access_id;
    }

    // Find access for sub-navigation items if they exist
    if (navItem.subNav) {
      navItem.subNav.forEach(subNavItem => {
        const subAccess = sidebarAccesses.find(acc =>
          acc.access_name === navItem.title &&
          acc.access_name_option === subNavItem.title
        );
        if (subAccess) {
          subNavItem.access_id = subAccess.access_id;
        }
      });
    }
  });

  sidebarAccesses.forEach((acc) => {
    const navItem = navigation.find(
      (nav) => {
        if (nav.access_id) {
          if (nav.access_id === acc.access_id) {
            return nav
          }
        } else if (nav.subNav) {
          let temporaryArr = []
          nav.subNav.forEach((subnav) => {
            if (subnav.access_id !== undefined) {
              if (subnav.access_id === acc.access_id) {
                temporaryArr.push(subnav)
              }
            }
          })
          return temporaryArr[0];
        }
      }
    );

    if (navItem) {
      let existingNavItem = accessArr.find(
        (item) => item.title === navItem.title
      );

      if (!existingNavItem) {
        existingNavItem = { ...navItem, subNav: [] };
        accessArr.push(existingNavItem);
      }

      if (navItem.subNav && acc.access_name_option) {
        const subNavItem = navItem.subNav.find(
          (sub) => sub.title === acc.access_name_option
        );
        if (subNavItem) {
          existingNavItem.subNav.push(subNavItem);
        }
      }
    }
  });
  accessArr.forEach((nav) => {
    if (nav.subNav && nav.subNav.length === 0) {
      delete nav.subNav;
    }
  });
}  else {
  accessArr = [
    {
      title: "Sandbox",
      path: "/sandbox",
      icon: Building,
      current: true,
    },
    {
      title: "Project",
      path: "/projects",
      icon: Building,
      current: true,
    }
  ]
}

var navigationRoutes = [];

navigationRoutes = accessArr
console.log(navigationRoutes);


export const NavigationRoutesContext = createContext();
export const CurrentNavigationRouteContext = createContext();

export const NavigationContextProvider = (props) => {
  if (navigationRoutes.length === 0) {
    return null; 
  }
  const [currentRoute, setCurrentRoute] = useState(navigationRoutes[0].title);
  const contextValue = { currentRoute, setCurrentRoute };
  return (
    <NavigationRoutesContext.Provider value={{ navigationRoutes }}>
      <CurrentNavigationRouteContext.Provider value={contextValue}>
        {props.children}
      </CurrentNavigationRouteContext.Provider>
    </NavigationRoutesContext.Provider>
  );
};

export const userRoutes = navigationRoutes;