import axios from "axios";
import { Edit, Trash } from "iconsax-react";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import DeleteModal from "../../../commons/components/deleteModal.jsx";
import Spinner from "../../../commons/components/spinner";
import Table from "../../../commons/components/table";
import { commonConfigs, urls } from "../../../config/config";
import UpdateAccessPageModal from "../components/updateAccessPagesModal";

const AccessPagesLayout = () => {
  const [load, setLoad] = useState(false);
  const [isUpdateModal, setUpdateModal] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [accessData, setAccessData] = useState([]);
  const [rowToEdit, setRowToEdit] = useState();
  let getAllAccessPagesUrl = ``;
  let deleteAccessPagesUrl =``;
  let apiHeaders;

  if (process.env.REACT_APP_ENV === "staging") {
    getAllAccessPagesUrl =
      urls.baseUrl +
      commonConfigs.apiUrls.getAllAccessPages();
    deleteAccessPagesUrl =
      urls.baseUrl
    apiHeaders = commonConfigs.apiHeader;
  } else {
    getAllAccessPagesUrl =
      urls.prodUrl +
      commonConfigs.apiUrls.getAllAccessPages();
    deleteAccessPagesUrl =
      urls.prodUrl
    apiHeaders = commonConfigs.prodApiHeader;
  }

  async function getAccessPages() {
    await axios
      .get(getAllAccessPagesUrl, { headers: apiHeaders })
      .then((res) => {
        console.log(res.data.result.data);
        setAccessData(res.data.result.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => { 
    getAccessPages();
  }, []);

  const columns = useMemo(() => [
    // {
    //   Header: "Access Id",
    //   accessor: "access_id",
    // },
    {
      Header: "Web Portal Sidebar",
      accessor: "access_name",
    },
    {
      Header: "Web Portal Sidebar Dropdown",
      accessor: "access_name_option",
      Cell: ({ row }) => {
        return (
          <div className="text-sm text-gray-900">
            {row.original.access_name_option
              ? row.original.access_name_option
              : "-"}
          </div>
        );
      },
    },
    {
      id: "edit",
      Cell: ({ row }) => {
        return (
          <div
            className=" text-gray-900 flex justify-end space-x-3"
            style={{ paddingRight: "15px" }}
          >
            <button
              type="button"
              onClick={() => {
                setUpdateModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Edit aria-hidden="true" className="w-6 h-6" />
            </button>
            <button
              type="button"
              onClick={() => {
                setDeleteModal(true);
                setRowToEdit(row.original);
              }}
            >
              <Trash aria-hidden="true" className="w-6 h-6" />
            </button>
          </div>
        );
      },
    },
  ]);

  if (!load) {
    return (
      <div className="w-full p-6">
        <div className="flex justify-end items-center mb-12 mt-2">
          {/* Button for 'Create Access Role' */}
          <Link to="/accesspages/create">
            <button
              type="button"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-12 rounded-full"
            >
              Create Navigation Page Access
            </button>
          </Link>
        </div>
        {accessData != null ? (
          <Table
            columns={columns}
            data={accessData}
            title="All Navigation Page Access"
            filters={columns.map((column) => column.accessor)}
          />
        ) : (
          <div className="w-screen h-screen flex flex-wrap flex-row place-items-center">
            <div className="flex place-content-center w-full">
              <Spinner className="w-20 h-20" />
            </div>
          </div>
        )}

        {isUpdateModal && (
          <UpdateAccessPageModal
            setOpenModal={setUpdateModal}
            load={setLoad}
            row={rowToEdit}
          />
        )}
        {isDeleteModal && (
          <DeleteModal setDeleteModal={setDeleteModal} load={setLoad} url={deleteAccessPagesUrl + commonConfigs.apiUrls.deleteAccessPage(rowToEdit.access_id)}/>
        )}
      </div>
    );
  }
};

export default AccessPagesLayout;
