import ProjectPageLayout from "./projectPageLayout";
import { ProjectsContextProvider } from "../context/projectsContext";

// this displays the layout for project page
const ProjectPage = () => {
  return (
    <ProjectsContextProvider>
      <ProjectPageLayout />
    </ProjectsContextProvider>
  );
};

export default ProjectPage;