import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Card from '../components/sandboxCard'
import { urls, commonConfigs } from "../../../config/config";

const SandboxPageLayout = () => {
  const [load, setLoad] = useState(true);
  const [data, setData] = useState([]);
  const ssoId = localStorage.getItem("ssoId");
  const getAllSandboxesUrl = urls.sandboxUrl + commonConfigs.apiUrls.GetAllDeveloperProjects(ssoId)
  async function getSandboxes() {
  await axios
  .get(getAllSandboxesUrl, { headers: commonConfigs.apiHeader })
  .then((res) => {
    console.log(res.data.result.data);
    setData(res.data.result.data);
    setLoad(false);
  })
  .catch((err) => {
    console.log(err);
  })}

  useEffect(() => { 
    getSandboxes();
  }, []);

  if (!load) {
    return (
      <div style={{width: '100%'}}>
        <div className="mt-4 desktop:space-x-8  mobile:pt-2  mobile:pb-4 mobile:space-x-2">
          {/* button for 'Create Project' */}
          <Link to="/sandbox/create">
            <button 
              type="button"
              className={`font-bold py-2.5 px-14 rounded-full mr-10 ${data.length >= 1 ? 'bg-gray-500 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-700 text-white'}`} 
              style={{float: 'right'}}
              disabled={data.length >= 1}
            >
              Create New Sandbox
            </button>
          </Link>
          {/* calling the project card component */}
          <div className="mt-12 flex flex-grow gap-x-2 justify-end pr-10" style={{display: 'grid', gridTemplateColumns: '2fr 2fr 2fr'}}>
            {data.map((item, index) => (
            <Card key = {index} data = {item} index = {index} />
            ))}
          </div>

        </div>
      </div>
    );
  }
};

export default SandboxPageLayout;
