import React, { useEffect, useState } from "react";
import { commonConfigs } from "../../../config/config";
import OtpModal from "../components/developerOtpModal";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

const ssoLogin = commonConfigs.apiUrls.ssoUserAuth();
const headers = commonConfigs.apiHeader;
const domain = window.location.host;

const errorIcon = (
  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
    {/* <InfoCircle className="h-5 w-5 text-red-500" aria-hidden="true" /> */}
  </div>
);

const AdminLoginPageLayout = () => {
  const [load, setLoad] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState("");
  const [isOpen, setOpen] = useState(false);
  const [data, setData] = useState();
  const [mobile, setMobile] = useState();
  const [resourceDomain, setResourceDomain] = useState();
  const {
    register,
    formState: { errors },
    handleSubmit,
    control
  } = useForm({});

  const resourceDomainOptions = [
    { value: "openapipaas", label: "OpenApiPaas" },
    { value: "OPENAPIPAAS", label: "Placeholder" },
  ];

  const onSubmit = (data) => {
    console.log(data);
    setMobile(data.mobile);
    setResourceDomain(data.resource_domain.value);
    localStorage.setItem('resourceDomain', data.resource_domain.value);
    setOpen(true);
    
  };

  const updateDateTime = () => {
    const now = new Date();

    // Function to get the current date and time
    const dateOptions = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    const timeOptions = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    // Formatted date and time
    const formattedDate = now.toLocaleDateString("en-US", dateOptions);
    const formattedTime = now.toLocaleTimeString("en-US", timeOptions);

    // Combine date and time into the desired format
    const formattedDateTime = `${formattedDate} ${formattedTime}`;
    setCurrentDateTime(formattedDateTime);
  };

  useEffect(() => {
    updateDateTime();
  }, []);

  if (!load) {
    return (
      <>
        <div style={{ width: "100%" }}>
          <div className="mt-4 desktop:space-x-8  mobile:pt-2  mobile:pb-4 mobile:space-x-2">
            <div style={{ textAlign: "center", marginTop: "9%" }}>
              <div
                style={{
                  fontSize: "40px",
                  letterSpacing: "30px",
                  fontWeight: "800",
                  color: "#3C4B64",
                }}
              >
                <h1 style={{ marginBottom: "10px" }}>OPENAPIPASS</h1>
                <h1
                  style={{
                    fontSize: "20px",
                    letterSpacing: "20px",
                    marginBottom: "5px",
                  }}
                >
                  FOR
                </h1>
                <h1 style={{ fontSize: "20px", letterSpacing: "20px" }}>
                  DEVELOPERS
                </h1>
              </div>
              <p style={{ color: "#8A93A2", fontSize: "16px" }}>
                {currentDateTime}
              </p>
              <div className="mt-8">
                <div className="mt-6">
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex flex-col items-center"
                  >
                    <div className="mt-2 sm:col-span-6 text-left">
                      <div className="mt-1 relative rounded-md ">
                        <input
                          type="text"
                          name="mobile"
                          id="mobile"
                          className={
                            !errors.mobile
                              ? "block w-96 pr-10 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md bg-white"
                              : "block w-96 pr-10 border-red-300 text-red-900 placeholder-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                          }
                          placeholder={"Enter Mobile Number"}
                          {...register("mobile", { required: true })}
                        />
                        {errors.mobile?.type === "required" && errorIcon}
                      </div>
                    </div>
                    <div className="sm:col-span-6 text-left mt-2">
                      <div className="mt-1 relative rounded-md">
                        <Controller
                          name="resource_domain"
                          control={control}
                          render={({ field }) => (
                            <Select
                              isClearable
                              {...field}
                              options={resourceDomainOptions}
                              isSearchable={false}
                              required={true}
                              placeholder="Select Resource Domain"
                              className="text-sm w-96"
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="mt-5 block w-96">
                      <button
                        type="submit"
                        style={{
                          backgroundColor: "#2D3648",
                          marginTop: "-33px",
                        }}
                        className="space-x-2 items-center w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        <span>Login</span>
                      </button>
                    </div>
                    <a href="/adminlogin" className="block w-96 text-center mt-2">
                      <div className="text-blue-500 font-bold text-sm py-2 group-hover:underline group-hover:decoration-blue-500">
                        Login as admin
                      </div>
                    </a>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isOpen && (
          <OtpModal setOpenModal={setOpen} data={data} mobile={mobile} resourceDomain={resourceDomain}/>
        )}
      </>
    );
  }
};

export default AdminLoginPageLayout;
